<template>
  <b-media no-body class="align-items-center">
    <b-media-aside>
      <b-avatar rounded :size="size" :variant="variant">
        <feather-icon :size="sizeIcon" :icon="icon" />
      </b-avatar>
    </b-media-aside>
    <b-media-body>
      <h6 class="transaction-title top-m">
        {{ keyText }} {{ keyText ? ":" : "" }} {{ text }}
      </h6>
    </b-media-body>
  </b-media>
</template>

<script>
import { BMedia, BMediaAside, BAvatar, BMediaBody } from "bootstrap-vue";
export default {
  props: {
    size: {
      type: String || Number,
      default: () => "35",
    },
    variant: {
      type: String,
      default: () => "light-primary",
    },
    sizeIcon: {
      type: String,
      default: () => "18",
    },
    icon: {
      type: String,
      default: () => "",
    },
    text: {
      type: String | Number,
    },
    keyText: {
      type: String,
      default: () => ""
    }
  },
  components: {
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
  },
};
</script>