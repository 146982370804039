<template>
  <b-card>
    <header-table title="الفعاليات" @create="onAdd" />
    <div class="border pt-1 px-1 rounded-lg mb-1 shadow-sm">
      <b-row>
        <b-col cols="5">
          <form-input label="" v-model="filter" placeholder="ابحث هنا..." class="w-50" />
        </b-col>
        <b-col cols="1"></b-col>
        <b-col cols="6">
          <div class="d-flex justify-content-end">
            <b-button v-for="(item, i) in filterDate" :key="i" size="md" variant="outline-success" pill class="mr-50 ml-50"
              @click=" utils.status = item.status; $refs.estatesTable.refreshTable();
              " :class="utils.status == item.status ? 'bg-light-success' : ''">{{ item.label }}</b-button>
          </div>
        </b-col>
      </b-row>
    </div>
    <data-table 
      ref="estatesTable" :fields="fields" ep="/events" :filter.sync="filter" :utils.sync="utils">
      <template #cell(actions)="row">
        <div class="d-flex">
          <!-- <button-more @open="row.toggleDetails" /> -->
          <actions-table @delete="onDelete(row.item)" @update="onUpdateClicked(row.item)"/>
        </div>
      </template>
      <template #call(name_ar)="data">
        <div>{{ data.item.id }}</div>
      </template>
      <template #row-details="row">
        <b-collapse id="collapse-1" class="mt-2">
          <b-row>
            <b-col cols="4">
              <b-carousel id="carousel-example-generic " controls indicators>
                <b-carousel-slide v-for="(item, i) in row.item.images" :key="i" :img-src="item.url"
                  style="height: 200px" />
              </b-carousel>
            </b-col>
            <b-col cols="3">
              <icon-media icon="ClockIcon" keyText="البداية"
                :text="$moment(row.item.from, ['h:mm']).locale('ar').format('hh:mm A')" class="mb-1"></icon-media>
              <icon-media icon="ClockIcon" keyText="النهاية"
                :text="$moment(row.item.to, ['h:mm']).locale('ar').format('hh:mm A')" class="mb-1"></icon-media>
              <icon-media icon="InfoIcon" keyText="هل هي مجانية" :text="!row.item.is_payment ? 'نعم' : 'لا'"
                class="mb-1"></icon-media>
              <icon-media v-if="row.item.cost" icon="InfoIcon" keyText="الكلفة"
                :text="row.item.cost.toLocaleString('en-US')" class="mb-1"></icon-media>
              <icon-media v-if="row.item.payment_method" icon="InfoIcon" keyText="طريقة الدفع"
                :text="row.item.payment_method ? 'كاش' : 'إلكتروني'" class="mb-1"></icon-media>
              <icon-media icon="InfoIcon" keyText="الحد الأعلى للحجز" :text="row.item.number_per_user"
                class="mb-1"></icon-media>
            </b-col>
            <b-col cols="5">
              <h6>التفاصيل</h6>
              <p>
                {{ row.item.description }}
              </p>
              <hr />
              <div v-if="row.item.reservation_information">
                <h6>تفاصيل الحجز</h6>
                <p>
                  {{ row.item.reservation_information }}
                </p>
              </div>
            </b-col>
          </b-row>
        </b-collapse>
      </template>
    </data-table>
    <form-modal ref="estateModalEvent" :formSchema="estatesFormSchema" title="إضافة" v-model="activModel"
      :loading="loading" @confirm="onModalConfirmed">
      <template #cost="item">
        <form-mask v-if="item.form.is_payment" label="الكلفة" options="number" rules="required" dir="ltr"
          placeholder="1,000" v-model="item.form.cost" />
      </template>
      <template #payment_method="item">
        <form-select v-if="item.form.is_payment" v-model="item.form.payment_method" id="payment_method"
          label="طريقة الدفع" rules="required" :reduce="(el) => el.value" :options="[
            { label: 'إلكتروني', value: 'E' },
            { label: 'كاش', value: 'C' },
          ]" />
      </template>
      <template #reservation_information="item">
        <form-textarea v-if="item.form.is_reservation" label="تفاصيل الحجز" rules="required"
          v-model="item.form.reservation_information" />
      </template>

      <template #images="item">
        <form-file label="صورة" :rules="item.form.id ? (staticImages.length==0 && deleted_last_images.length==0 ? 'required' : '') : (staticImages.length==0 ? 'required' : '')" accept="image/*" v-model="imageSlide" id="form-file" name="image"
          placeholder="لم تقم باختيار صور" multiple @change="previewImage" />
        <div class="text-center mb-1">
          <layout-input-image v-for="(item, i) in staticImages" :key="i" :url="item.url" :name="item.name"
            :is_last_imge="false" @deleteItem="deleteImage(item)">
          </layout-input-image>
          <layout-input-image v-for="(item, i) in deleted_last_images" :key="i" :url="item.url" :is_last_imge="true"
            @deleteItem="deleteLastImage(item)">
          </layout-input-image>
        </div>
      </template>
    </form-modal>
  </b-card>
</template>
  
<script>
import {
  BCard,
  BButton,
  VBTooltip,
  BCollapse,
  VBToggle,
  BRow,
  BCol,
  BMediaBody,
  BMedia,
  BMediaAside,
  BAvatar,
  BImg, 
  BDropdown,
  BDropdownItem,
  BCarousel, 
  BCarouselSlide
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import { mapActions, mapGetters } from "vuex";
import LayoutInputImage from "@/components/LayoutInputImage.vue";
import FormModal from "@/components/FormModal.vue"
import FormFile from "@/components/form-file/index.vue"
import FormInput from "@/components/form-input/index.vue"
import DataTable from "@/components/data-table/index.vue"
import FormSelect from "@/components/form-select/index.vue"
import FormMask from "@/components/form-mask/index.vue"
import IconMedia from "@/components/icon-media.vue"
import FormTextarea from "@/components/form-textarea/index.vue"
import ActionsTable from "@/components/data-table/components/actions-table.vue"
import HeaderTable from "@/components/data-table/components/header-table.vue";

export default {
  components: {
    ActionsTable,
    FormTextarea,
    FormMask,
    FormSelect,
    BCard,
    BButton,
    DataTable,
    VBTooltip,
    AppCollapse,
    AppCollapseItem,
    BCollapse,
    FormModal,
    FormFile,
    BRow,
    BCol,
    FormInput,
    BCarousel,
    BCarouselSlide,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BImg,
    IconMedia,
    BDropdown,
    BDropdownItem,
    LayoutInputImage,
    HeaderTable,
},
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,

    Ripple,
  },
  data: () => {
    return {
      utils: {
        status: "",
      },

      filterDate: [
        { id: 1, label: "كل الفعاليات", status: "" },
        { id: 1, label: "الفعاليات القادمة", status: "upcoming" },
        { id: 1, label: "الفعاليات السابقة", status: "previous" },
      ],

      fields: [
        { key: "title", label: "اسم الفعالية", sortable: true },
        { key: "location", label: "الموقع" },
        { key: "starting_date", label: "تاريخ البداية" },
        { key: "closing_date", label: "تاريخ النهاية" },
        { key: "max_member", label: "عدد المقاعد الكلي" },
        { key: "actions", label: "" },
      ],
      imageSlide: [],
      staticImages: [],
      deleted_images: [],
      deleted_last_images: [],

      activModel: false,
      cost: "",
      payment_method: "",
      filter: "",
      reservation_information: "",

      estatesFormSchema: {
        title: {
          component: "FormInput",
          key: "title",
          attrs: {
            label: "اسم فعالية",
            rules: "required",
          },
        },
        description: {
          component: "FormTextarea",
          key: "description",
          attrs: {
            label: "التفاصيل",
            rules: "required",
          },
        },
        is_reservation: {
          component: "FormSelect",
          key: "is_reservation",
          attrs: {
            id: "is_reservation",
            label: "إمكانية حجز مقعد",
            rules: "required",
            reduce: (el) => el.value,
            options: [
              { label: "لا", value: 0 },
              { label: "نعم", value: 1 },
            ],
          },
        },
        reservation_information: {
          key: "reservation_information",
        },

        is_payment: {
          component: "FormSelect",
          key: "is_payment",
          attrs: {
            id: "is_payment",
            label: "هل هي مجانية",
            rules: "required",
            reduce: (el) => el.value,
            options: [
              { label: "نعم", value: 0 },
              { label: "لا", value: 1 },
            ],
          },
        },
        cost: {
          key: "cost",
        },
        payment_method: {
          key: "payment_method",
        },

        max_member: {
          component: "FormMask",
          key: "max_member",
          attrs: {
            label: "عدد المقاعد الكلي",
            options: "number",
            rules: "required",
            dir: "ltr",
            placeholder: "",
          },
        },
        number_per_user: {
          component: "FormMask",
          key: "number_per_user",
          attrs: {
            label: "الحد الأعلى للحجز",
            options: "number",
            rules: "required",
            dir: "ltr",
            placeholder: "",
          },
        },
        location: {
          component: "FormInput",
          key: "location",
          attrs: {
            label: "العنوان",
            rules: "required",
          },
        },
        starting_date: {
          component: "FormDatepicker",
          key: "starting_date",
          attrs: {
            id: "starting_date_event",
            label: "تاريخ البداية",
            rules: "required",
            placeholder: "YY-MM-DD",
          },
        },
        closing_date: {
          component: "FormDatepicker",
          key: "closing_date",
          attrs: {
            id: "closing_date_event",
            label: "تاريخ النهاية",
            rules: "required",
            placeholder: "YY-MM-DD",
          },
        },
        from: {
          component: "FormTimepicker",
          key: "from",
          attrs: {
            id: "form_time__pickerop_event",
            label: "توقيت البدء",
            rules: "required",
            placeholder: "HH-MM",
          },
        },
        to: {
          component: "FormTimepicker",
          key: "to",
          attrs: {
            id: "to_time__picker_event",
            label: "توقيت النهاية",
            rules: "required",
            placeholder: "HH-MM",
          },
        },
        images: {
          key: "images",
          attrs: {
            rules: "required",
          },
        },
      },
    };
  },
  methods: {
    ...mapActions("manageApp/event", [
      "deleteEvent",
      "createEvent",
      "updateEvent",
    ]),

    deleteImage(item) {
      this.imageSlide = this.imageSlide.filter(
        (file) => file.name !== item.name
      );
      this.staticImages = this.staticImages.filter(
        (file) => file.name !== item.name
      );
    },
    onDelete(item) {
      // console.log(item)
      this.$bvModal
        .msgBoxConfirm("هل أنت متأكد من حذف الفعالية", {
          title: "تأكيد حذف الفعالية",
          size: "sm",
          okVariant: "danger",
          okTitle: "تأكيد",
          cancelTitle: "إلغاء",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value)
            this.deleteEvent({ id: item.id }).then(() => {
              this.$refs.estatesTable.refreshTable();
            });
        });
    },
    deleteLastImage(item) {
      this.deleted_images.push(item.id);
      this.deleted_last_images = this.deleted_last_images.filter(
        (data) => data.id != item.id
      );
    },
    onUpdateClicked(form) {
      var toUpdate = {
        id: form.id,
        title: form.title,
        description: form.description,
        location: form.location,
        starting_date: form.starting_date,
        closing_date: form.closing_date,
        from: form.from,
        to: form.to,
        is_payment: form.is_payment,
        max_member: form.max_member,
        number_per_user: form.number_per_user,
        is_reservation: form.is_reservation,
        reservation_information: form.reservation_information,
        cost: form.cost,
        payment_method: form.payment_method,
        filter: "",
      };
      this.deleted_last_images = form.images;
      this.$refs.estateModalEvent.init(toUpdate);
      this.activModel = true;
      this.imageSlide = [];
      this.staticImages = [];
      this.deleted_images = [];
    },
    onModalConfirmed(form) {
      var fd = new FormData();
      for (const key in form) {
        if (Object.hasOwnProperty.call(form, key)) {
          let element = form[key];
          if (form[key] != null) {
            if (key == "cost" || key == "max_member" || key == "number_per_user")
              fd.append(key, element.replace(/\D/g, ""));
            else fd.append(key, element);
          }
        }
      }
      if (this.imageSlide.length != 0)
        for (let i = 0; i <= this.imageSlide.length; i++) {
          fd.append("images[]", this.imageSlide[i]);
        }
      if (this.deleted_images.length !== 0)
        for (let i = 0; i < this.deleted_images.length; i++) {
          fd.append("deleted_images[]", this.deleted_images[i]);
          console.log(this.deleted_images[i]);
        }
      if (form.id) {
        fd.append("_method", "PUT");
        this.updateEvent({ id: form.id, fd: fd }).then((res) => {
          if(res.status>=200 && res.status<300){
            this.$refs.estatesTable.refreshTable();
            this.activModel = false;
          }
        });
      } else
        this.createEvent({ fd: fd }).then((res) => {
          if(res.status>=200 && res.status<300){
            this.$refs.estatesTable.refreshTable();
            this.activModel = false;
          }
        });
    },
    onAdd() {
      this.$refs.estateModalEvent.init({});
      this.imageSlide = [];
      this.staticImages = [];
      this.deleted_images = [];
      this.deleted_last_images = [];
      this.activModel = true;
    },

    previewImage(e) {
      this.staticImages = Array.from(e.target.files).map((file) => ({
        url: URL.createObjectURL(file),
        name: file.name,
      }));
    },
  },
  computed: {
    ...mapGetters("manageApp/event", ["loading"]),
  },
};
</script>