<template>
  <validation-provider
    #default="{ errors }"
    :name="defaultName"
    :vid="id"
    :rules="rules"
    mode="passive"
  >
    <b-form-group :label="label" :label-for="id">
      <v-select
        :options="options"
        :name="name"
        :dir="isRTL"
        :id="id"
        :loading="loading"
        v-bind="$attrs"
        v-on="$listeners"
        :class="{ 'has-error': errors.length > 0 && !$attrs.value }"
      >
        <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
          <slot :name="name" v-bind="data"></slot>
        </template>
        <template #no-options> لا يوجد نتائج لعرضها </template>
        <template #spinner="{ loading }">
          <b-spinner
            small
            variant="primary"
            v-if="loading"
          ></b-spinner> </template
      ></v-select>
      <small class="text-danger" v-if="errors.length > 0 && !$attrs.value">
        {{ errors[0] }}
      </small>
    </b-form-group>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import vSelect from "vue-select";
import { BFormGroup, BSpinner } from "bootstrap-vue";
import store from "./store";
import { $themeConfig } from "@themeConfig";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
} from "@validations";
export default {
  name:'FormSelect',
  // functional: true,
  components: {
    ValidationProvider,
    vSelect,
    BSpinner,
    BFormGroup,
  },
  props: {
    id: {
      type: String,
      default: () => "select",
    },
    name: {
      type: String,
    },
    rules: {
      type: String,
      default: () => "",
    },
    label: {
      type: String,
      default: () => "",
    },
    ep: {
      type: String,
      default: () => null,
    },
    dataPath: {
      type: String,
      default: () => "data",
    },
    utils:{
      type: Object,
      default: () => { return {}}
    }
  },
  data() {
    return {
      required,
      email,
      confirmed,
      url,
      between,
      alpha,
      integer,
      password,
      min,
      digits,
      alphaDash,
      length,
    };
  },
  computed: {
    isRTL() {
      return $themeConfig.layout.isRTL ? "rtl" : "rtl";
    },
    options() {
      return this.$store.getters[`${this.id}/options`] || this.$attrs.options;
    },
    loading() {
      return this.$store.getters[`${this.id}/loading`];
    },
    defaultName() {
      return this.name || this.label;
    },
  },
  watch:{
    id(){
      this.refreshOptions()
    }
  },
  methods: {
    refreshOptions(val) {
      if (this.$store.hasModule(this.id)) {
        this.$store.dispatch(`${this.id}/fetchOptions`, {
          ep: val || this.ep,
          path: this.dataPath,
          qp:this.utils
        });
      }
    },
  },
  created() {
    if (this.ep && !this.$attrs.options && !this.$store.hasModule(this.id)) {
      this.$store.registerModule(this.id, store);
      if (this.ep.length > 1) {
        this.$store.dispatch(`${this.id}/fetchOptions`, {
          ep: this.ep,
          path: this.dataPath,
          qp:this.utils
        });
      }
    }
  },
  beforeDestroy() {
    if (this.$store.hasModule(this.id)) {
      this.$store.unregisterModule(this.id);
    }
  },
};
</script>

<style lang="scss">
.has-error {
  .vs__dropdown-toggle {
    border: 1px solid #ea5455 !important;
  }
}
</style>
