<template>
    <div>
        <div class="d-flex justify-content-between align-items-center w-100 mb-1">
            <h3> {{ title }}</h3>
            <div v-can="{ slug: $route.meta.slug, action: 'C' }">
                <b-button variant="primary" @click="$emit('create')">
                    <feather-icon icon="editIcon" />{{ titleButton }} 
                </b-button>
            </div>
        </div>
        <hr />
    </div>
</template>

<script>
import { BButton } from "bootstrap-vue";
export default {
    props: {
        title: String,
        titleButton: {
            type: String,
            default:()=>"إضافة"
        }
    },
    components:{
        BButton
    }
}
</script>