import axios from "@axios";
import { get } from "lodash";
export default {
  namespaced: true,
  state: () => ({
    loading: false,
    options: [],
  }),
  getters: {
    loading(state) {
      return state.loading;
    },
    options(state) {
      return state.options;
    },
  },
  mutations: {
    setLoading(state, payload) {
      state.loading = payload;
    },

    setOptions(state, payload) {
      state.options = payload;
    },
  },
  actions: {
    async fetchOptions({ commit }, payload = { ep: "", qp: {}, path: "data" }) {
      commit("setLoading", true);
      try {
        const data = await axios.get(payload.ep, {params:payload.qp}).then((res) => res.data);
        const resolved = get(data, payload.path);
        commit("setOptions", resolved);
      } catch (err) {
        console.error(err);
      } finally {
        commit("setLoading", false);
      }
    },
  },
};
