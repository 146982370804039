<template>
  <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
    <template #button-content>
      <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />

    </template>
    <slot name="start-extend-dropdown-item"></slot> 
    <b-dropdown-item v-can="{ slug: $route.meta.slug, action: 'U' }"  @click="$emit('update')">
      <feather-icon icon="EditIcon" />
      <span class="align-middle ml-50">تعديل</span>
    </b-dropdown-item>

    <b-dropdown-item v-can="{ slug: $route.meta.slug, action: 'D' }" @click="$emit('delete')">
      <feather-icon icon="Trash2Icon" />
      <span class="align-middle ml-50">حذف</span>
    </b-dropdown-item>
    <slot name="extend-dropdown-item"></slot> 
  </b-dropdown>
</template>

<script>
import { BDropdown, BDropdownItem, BButton } from "bootstrap-vue";
export default {
  props: {
    noDelete: {
      type: Boolean,
      default: false,
    },
    noUpdate: {
      type: Boolean,
      default: false,
    },
  },
  // created(){
  //   console.log("test router", this.$route)
  // },
  
  components: {
    BDropdown,
    BDropdownItem,
    BButton,
  },
};
</script>
